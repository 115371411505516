<template>
  <div id="CompanyNews">
    <MHeader />
    <div class="banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/other/news_banner_m.jpg"
        alt=""
        srcset=""
      />
    </div>
    <div class="swiper_box">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in banner_list" :key="index">
          <img :src="item.img_mobile" alt="" srcset="" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="list">
      <div
        class="list_inside"
        v-for="(item, index) in list"
        :key="index"
        @click="news_detail(item)"
      >
        <div class="list_item">
          <div class="date_container">
            <div>{{ item.date_day }}</div>
            <div>{{ item.yyyy_mm }}</div>
          </div>
          <div class="img_box">
            <img :src="item.img_mobile" alt="" />
          </div>
          <div class="text_content">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="content">
              {{ item.desc }}
            </div>
            <div class="more_btn">查看更多 ></div>
          </div>
        </div>
      </div>
    </div>
    <MobileConncatUs />
    <MFooter />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import MobileConncatUs from "@/components/MobileConncatUs";

import { queryNewsList, queryNewsBannerList } from "@/api/index";
export default {
  name: "CompanyNews",
  components: {
    swiper,
    swiperSlide,
    MHeader,
    MFooter,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        watchSlidesProgress: true,
        initialSlide: 1, //默认第二个
        // 设定slide与左边框的预设偏移量（单位px）
        // slidesOffsetBefore: 25,
        // slidesOffsetAfter:25,
        // 设置slide之间的距离（单位px）
        spaceBetween: 12.5,
        centeredSlides: true,
        loop: true,
        autoplay: 3000,
      },
      list: [],
      banner_list: [],
      data_source: [
        /* {
          date_day:"",
          yyyy_mm:"",
          img_mobile:"",
          title:"",
          desc:"",
        } */
      ],
      loading: false,
      finished: false,
    };
  },
  methods: {
    news_detail(item) {
      if (item.link != "") {
        window.location.href = item.url;
      } else {
        this.$router.push("/mtest");
      }
    },
    onLoad() {
      setTimeout(() => {
        for (let i = 0; i < 5; i++) {
          this.data_source.push(this.list[this.data_source.length]);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.data_source.length >= this.list.length) {
          this.finished = true;
        }
      }, 500);
    },
    queryNewsList() {
      queryNewsList({
        page: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.data;
          this.list.forEach((item) => {
            /* item.yyyy_mm = "";
            item.date_day = ""; */
            let splite_date = item.date.split("-");
            item.yyyy_mm = splite_date[0] + "-" + splite_date[1];
            item.date_day = splite_date[2];
          });
        }
      });
    },
    queryNewsBannerList() {
      queryNewsBannerList({
        page: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.data.code == 200) {
          this.banner_list = res.data.data.data;
        }
      });
    },
  },
  created() {
    this.queryNewsList();
    this.queryNewsBannerList();
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#CompanyNews {
  width: 100vw;
  margin-top: 18.667vw;
  overflow: hidden;
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .swiper_box {
    width: 100%;
    height: 34.667vw;
    overflow: hidden;
    margin-top: 6.667vw;
    .swiper-slide {
      width: 50.667vw;
      /* height: 36vw; */
      height: 34.667vw;
      overflow: hidden;
      img {
        width: 100%;
        height: 34.667vw;
        display: block;
        margin: 0.667vw 0;
        transition: all 0.5s ease;
        font-size: 2.667vw;
        color: white;
      }
    }
    /* .swiper-slide-active {
      width: 50.667vw;
      height: 36vw;
      img {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 20px;
        margin: 0;
        color: white;
      }
    } */
  }
  .list {
    width: 100%;
    margin: 6.667vw 0;
    .list_inside {
      width: 93.6vw;
      height: 20vw;
      margin: 4.267vw 3.2vw 0;
      overflow: hidden;
      .list_item {
        width: 100%;
        height: 100%;
        /*  margin-top: 4.267vw; */
        .date_container {
          width: 11.2vw;
          /* height: 210px; */
          margin-top: 1.333vw;
          float: left;
          & > div:nth-child(1) {
            font-size: 8vw;
            font-weight: bold;
          }
          & > div:nth-child(2) {
            font-size: 2.667vw;
            font-weight: bold;
          }
        }
        .img_box {
          width: 30.667vw;
          height: 20vw;
          float: left;
          margin-left: 2.4vw;
          background: #e9e9df;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text_content {
          width: calc(100% - 11.2vw - 2.4vw - 30.667vw - 2.4vw);
          margin-left: 2.4vw;
          float: left;
          .title {
            width: 100%;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //显示省略号
            white-space: nowrap; //强制不换行
            font-size: 14px;
            font-weight: 400;
            color: #000000;
          }
          .content {
            width: 100%;
            margin-top: 0.9vw;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; //显示行数
            -webkit-box-orient: vertical;
            word-break: break-all;
            font-size: 11px;
            font-weight: 400;
            color: #504d4d;
          }
          .more_btn {
            font-size: 2.933vw;
            font-weight: 400;
            color: #999797;
            margin-top: 0.6vw;
          }
        }
      }
    }
  }
}
</style>
